import React from 'react';
import {
  Box,
  Heading,
  Skeleton,
  Text,
  Center,
  VStack,
  Image,
  Button,
  Link,
} from '@chakra-ui/react';
import { createClient } from '@supabase/supabase-js';
import { useQuery } from '@supabase-cache-helpers/postgrest-swr';
import { useRoute } from 'wouter';
import SocialShareWidget from './SocialShareWidget';

const API_KEY = process.env.REACT_APP_API_KEY as string;
const DATABASE_URL = process.env.REACT_APP_DATABASE_URL as string;

const ArticlePage = (): JSX.Element => {
  const supabase = createClient(DATABASE_URL, API_KEY);
  const [match, article] = useRoute('/article:id');

  const { data, isLoading } = useQuery(
    article
      ? supabase
          .from('Images')
          .select('*')
          .eq('id', BigInt(article.id.replace(':', '')))
      : null,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return (
    <Box height={'100vh'} width={'100%'}>
      <Skeleton isLoaded={!isLoading}>
        {data && (
          <Center m={5}>
            <VStack spacing={2}>
              <Heading color={'blue'} p={2}>
                {data[0].headline_text}
              </Heading>

              <Image src={data[0].image_src} alt={data[0].headline_text} />
              <SocialShareWidget newsItem={data[0]} />
              <Text>{data[0].article_text}</Text>
            </VStack>
          </Center>
        )}
      </Skeleton>
    </Box>
  );
};

export default ArticlePage;
