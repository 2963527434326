import React, { useEffect } from 'react';
import './App.css';
import {
  SimpleGrid,
  Text,
  Box,
  ChakraProvider,
  Heading,
  Center,
  VStack,
} from '@chakra-ui/react';
import { createClient } from '@supabase/supabase-js';
import { useQuery } from '@supabase-cache-helpers/postgrest-swr';
import { Switch, Route, Router } from 'wouter';
import theme from './theme';
import ArticleCard from './ArticleCard';
import ArticlePage from './ArticlePage';
import ReactGA from 'react-ga4';
import {
  BaseLocationHook,
  navigate,
  useLocationProperty,
} from 'wouter/use-location';
import PageLayout from './PageLayout';

ReactGA.initialize('G-EV4J893E9S');

function App() {
  // returns the current hash location in a normalized form
  // (excluding the leading '#' symbol)
  const hashLocation = () => window.location.hash.replace(/^#/, '') || '/';

  const hashNavigate = (to: string) => navigate('#' + to);

  const useHashLocation = () => {
    const location = useLocationProperty(hashLocation);
    return [location, hashNavigate];
  };
  const date = new Date();

  return (
    <ChakraProvider theme={theme}>
      <Router hook={useHashLocation as BaseLocationHook}>
        <Switch>
          <Route path="/">
            {/*  */}
            <PageLayout
              table="Images"
              select="*"
              // equals={{
              //   column: 'day',
              //   query: `${date.getUTCFullYear()}-${
              //     date.getUTCMonth() + 1
              //   }-${date.getUTCDate()}`,
              // }}
            />
          </Route>
        </Switch>
        <Route path={'/article:id'}>
          <ArticlePage />
        </Route>
        {/* <Route>
          <PageLayout
            table="Images"
            select="*"
            equals={{
              column: 'day',
              query: `${date.getUTCFullYear()}-${
                date.getUTCMonth() + 1
              }-${date.getUTCDate()}`,
            }}
          />
        </Route> */}
      </Router>
    </ChakraProvider>
  );
}

export default App;
