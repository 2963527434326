import React from 'react';
import {
  SimpleGrid,
  Text,
  Box,
  Heading,
  Center,
  VStack,
  HStack,
} from '@chakra-ui/react';
import ArticleCard from './ArticleCard';
import { createClient } from '@supabase/supabase-js';
import { useQuery } from '@supabase-cache-helpers/postgrest-swr';

const PageLayout = (props: {
  table: string;
  select: string;
  // equals: { column: string; query: string };
}) => {
  const API_KEY = process.env.REACT_APP_API_KEY as string;
  const DATABASE_URL = process.env.REACT_APP_DATABASE_URL as string;
  const supabase = createClient(DATABASE_URL, API_KEY);

  const { data, isLoading } = useQuery(
    supabase
      .from(props.table)
      .select(props.select),
      // .eq(props.equals.column, props.equals.query),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return (
    <Box height={'100vh'}>
      <Center>
        <Box
          h={{
            base: 'sm',
            xs: 'md',
            sm: 'lg',
            md: '1xl',
            lg: '2xl',
            xl: '3xl',
          }}
          w="100%"
          backgroundColor="brand.500"
          backgroundPosition="center"
          backgroundSize="cover"
          shadow={'xl'}
          m={2}
        >
          <VStack>
            <Center>
              <VStack>
                <Heading
                  as="h1"
                  size={{
                    base: '2xl',
                    xs: '2xl',
                    sm: '2xl',
                    md: '2xl',
                    lg: '2xl',
                    xl: '3xl',
                  }}
                  noOfLines={1}
                  color={'white'}
                  p={5}
                >
                  Echo Chamber Post
                </Heading>
                <Text
                  fontSize={{
                    base: '24px',
                    xs: '24px',
                    sm: '2xl',
                    md: '2xl',
                    lg: '3xl',
                    xl: '4xl',
                  }}
                  color={'white'}
                  p={2}
                >
                  Enter the Culture War
                </Text>
              </VStack>
            </Center>
          </VStack>
        </Box>
      </Center>
      <HStack ml={2} mr={2} bg={'brand.500'}>
        {/* map of the different pages */}
      </HStack>
      <SimpleGrid
        bg={'white'}
        m={5}
        columns={{
          'base': 1,
          'sm': 1,
          'md': 2,
          'lg': 3,
          'xl': 3,
          '2xl': 3,
        }}
        spacing={1}
      >
        {data &&
          // @ts_ignore
          data.map((newsItem: any, index: number) => {
            return <ArticleCard newsItem={newsItem} key={index} />;
          })}
      </SimpleGrid>
    </Box>
  );
};

export default PageLayout;
