import React from 'react';
import { Card, CardBody, Image, Heading, CardFooter } from '@chakra-ui/react';
import { Link } from 'wouter';
import SocialShareWidget from './SocialShareWidget';

const ArticleCard = (newsItem: any): JSX.Element => {
  return (
    <Card maxW="lg">
      <CardBody>
        <Link to={`/article:${newsItem.newsItem.id}`}>
          <Heading size="md" m={2} cursor={'pointer'}>
            {newsItem.newsItem.headline_text}
          </Heading>
        </Link>
        <Image
          src={newsItem.newsItem.image_src}
          alt={newsItem.newsItem.headline_text}
        />
      </CardBody>

      <CardFooter>
        <SocialShareWidget newsItem={newsItem.newsItem} />
      </CardFooter>
    </Card>
  );
};

export default ArticleCard;
