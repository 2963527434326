import { HStack } from '@chakra-ui/react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import React from 'react';

const SocialShareWidget = (newsItem: any): JSX.Element => {
  const rootURL = 'https://echochamberpost.com/article:';
  return (
    <HStack spacing={1}>
      <FacebookShareButton
        url={`${rootURL}${newsItem.newsItem.id}`}
        quote={newsItem.newsItem.headline_text}
      >
        <FacebookIcon />
      </FacebookShareButton>
      <WhatsappShareButton
        url={`${rootURL}${newsItem.newsItem.id}`}
        title={newsItem.newsItem.headline_text}
      >
        <WhatsappIcon />
      </WhatsappShareButton>
      <TwitterShareButton
        url={`${rootURL}${newsItem.newsItem.id}`}
        title={newsItem.newsItem.headline_text}
      >
        <TwitterIcon />
      </TwitterShareButton>
    </HStack>
  );
};

export default SocialShareWidget;
