import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const theme = extendTheme(
  {
    colors: {
      black: '#03171C',
      white: '#FFFFFF',
      brand:
        // colors from https://smart-swatch.netlify.app/#002C38 hue down

        {
          50: '#ffe2ec',
          100: '#ffb3c5',
          200: '#fc839e',
          300: '#fa5277',
          400: '#f72251',
          500: '#dd0837',
          600: '#ad022b',
          700: '#7c001e',
          800: '#4d0010',
          900: '#200005',
        },
    },
  },
  {
    fonts: {
      heading: `'Montserrat', sans-serif`,
      body: `'Montserrat', sans-serif`,
    },
  },
  withDefaultColorScheme({
    colorScheme: 'brand',
  })
);

export default theme;
